$table-header-height: 2.5rem;

$statuses: (
  'is-active': $success,
  'is-completed': $success,
  'has-error': $error,
  'is-in-progress': $color-brand,
  'is-cancelled': $almost-black,
);

.listing-page-table {
  margin-bottom: 1px;

  &__kebab {
    &[aria-expanded='true'] {
      border: 2px solid $color-brand-dark;
    }
  }

  &__title {
    max-width: calc(100% - #{rem-calc(32)});
    min-width: rem-calc(120);
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  &__link {
    color: $dark-primary-text;
    line-height: 1.3;
    text-decoration: none;
    font-weight: $font-weight-semibold;
    padding: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow-wrap: break-word;

    .listing-page-table__title {
      display: inline-block;
      padding: 0;
    }

    &-wrapper {
      display: flex;
    }
  }

  &__chips {
    align-items: center;
    padding: 0.5rem;
  }

  &__chip {
    @include text-style($size: 'medium', $weight: 500);
    background-color: $controls-background-selected;
    padding: 0.25rem 1rem;
    border-radius: rem-calc(10);
    margin: 0 1rem;
    white-space: nowrap;

    &--success {
      color: $light-primary-text;
      background-color: $success;
    }
  }

  &__toggle {
    @include dropdown-icon;
  }

  &__tooltip--multiline {
    white-space: pre-line;
  }

  &__info,
  &__loader {
    height: rem-calc(56);
    padding: 0.5rem 1rem;
    @include flex-container;
  }

  &__info {
    justify-content: flex-start;
  }

  &__decorator {
    @include until-tablet-landscape {
      display: none;
    }
  }

  &__cell {

    &-wrapper {
      display: flex;
      padding-left: 1rem;
    }

    &-checkbox {
      padding: 1rem 0 1rem 1rem;
    }
  }

  &--status {
    @each $status, $color in $statuses {
      &.#{$status} {
        background-color: $color;
      }
    }
    min-width: 0.4rem;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.mat-table {
    width: 100%;
    border-collapse: separate;

    .mat-header-cell,
    .mat-cell {
      @include text-style;
      border-bottom-color: $white;
      padding: 0 1rem;
    }

    .mat-header-cell {
      height: $table-header-height;

      @include for-tablet-landscape-up {
        white-space: nowrap;
      }

      &:first-of-type {
        padding: 0.25rem 1rem 1.25rem;
      }

      .mat-sort-header-content {
        text-align: left;
      }

      &.listing-page-table__cell--right {
        .mat-sort-header-container {
          justify-content: flex-end;
        }

        .mat-sort-header-arrow {
          position: absolute;
          right: -18px;
        }
      }

      &.listing-page-table__cell--center {
        .mat-sort-header-container {
          justify-content: center;
        }
        .mat-sort-header-content::before {
          content: '';
          display: block;
          width: 18px;
          height: 10px;
        }
      }

      &.cdk-keyboard-focused {
        .mat-sort-header-container,
        .mat-sort-header-button {
          display: inline-flex;
          border-bottom: none;

          &:focus {
            box-shadow: 0 0 0 2px $white, 0 0 0 4px $color-brand-dark;
          }
        }
      }
    }

    .mat-cell {
      background-color: $background-color;
      position: relative;
      border-top: 1px solid transparent;
      vertical-align: middle;

      @include for-tablet-landscape-up {
        white-space: nowrap;
      }

      &:not(:only-child):first-of-type {
        padding-left: 0;
        width: 100%;
        border-left: 1px solid transparent;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        white-space: normal;
        word-break: break-word;

        @at-root .listing-page-table--with-status#{&} {
          border-left: 0.25rem solid $border-color-secondary;
        }
      }

      &:not(:only-child):last-of-type {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-right: 1px solid transparent;
      }

      &:only-child {
        width: 100%;
        border-radius: $border-radius;
      }
    }

    .listing-page-table__cell {
      &--with-icon {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        width: rem-calc(40);
      }

      &--with-status {
        display: inline-flex;
      }

      &--simple {
        border-top: none;
        border-bottom: none;
        padding: 0;
        border-left: none;
        background: $background-active-light;
      }

      &--center {
        text-align: center;
      }

      &--right {
        text-align: right;
      }
    }

    .mat-row,
    .mat-header-row {
      height: auto;

      &:hover {
        .mat-cell:not(.listing-page-table__cell--simple) {
          border-top-color: $border-color-secondary;
          border-bottom-color: $border-color-secondary;

          &:last-of-type {
            border-right-color: $border-color-secondary;
          }

          &:first-of-type {
            border-left-color: $border-color-secondary;
          }
        }
      }
    }

    .mat-row {
      &.is-hidden {
        display: none;
      }

      &.is-expanded {
        position: sticky;
        top: $table-header-height;
        z-index: 100;

        .mat-cell:not(.listing-page-table__cell--simple) {
          background-color: $controls-background-active;
          border-top-color: $border-color-secondary;
          border-bottom: none;

          &:last-of-type {
            border-bottom-right-radius: 0;
            border-right-color: $border-color-secondary;
          }

          &:first-of-type {
            border-bottom-left-radius: 0;
            border-left-color: $border-color-secondary;
          }
        }
      }

      @at-root .listing-page-table--with-status#{&} {
        @each $status, $color in $statuses {
          &.#{$status} .mat-cell:first-of-type {
            border-left-color: $color;
          }
        }
      }
    }
  }
}
