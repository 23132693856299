.listing-page {
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__search {
    flex: 0 0 50%;

    &--hide-empty:empty {
      display: none;
    }
  }

  &__button.mat-button-base {
    padding: .25rem 3rem;
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  &__content,
  &__content1 {
    height: 100%;
    display: grid;

  }

  &__content {
    grid-template-rows: min-content auto auto minmax(auto, 1fr);
  }

  &__content1 {
    grid-template-rows: auto min-content auto auto minmax(auto, 1fr);
  }

  &-table-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1 1 auto;
    margin: 0 -1rem;
  }

  ngx-simplebar {
    max-height: 100%;
    padding: 0 1rem;
  }

  &-layout {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;

    &__item {
      flex: 1 1 100%;
      padding-left: 1rem;
    }
  }

  &__control-panel {
    display: grid;
    grid-template-columns: min-content min-content min-content;
    gap: 1rem;
    align-items: center;

    &-checkbox {
      margin: 0  0 1rem 1rem;
    }
  }
}
