html,
body {
  height: 100%;
}

body {
  font-size: .875rem;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a,
button:not([disabled]) {
  @include fix-focus-base;
}

strong {
  font-weight: $font-weight-bold;
}


