.mat-tab-body.mat-tab-body-active {
  animation: fade .3s;
}

.tabs {

  .mat-tab-header {
    margin-bottom: 1.5rem;
  }

  .mat-tab-label {
    min-width: 0;
    height: $panel-header-height;
    background-color: $background-color;
    border-bottom: none;
    opacity: 1;
    transition: background-color .3s;
    margin:  0 1px;
    @include text-style($size: 'medium');
    flex: 1 1 100%;

    @include until-tablet-landscape {
      padding: 0 1rem;
    }

    &.cdk-keyboard-focused {
      outline: none;
      box-shadow: inset 0 0 0 2px $color-brand-dark;
    }

    &:first-of-type {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-of-type {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.mat-tab-label-active {
      background-color: $grey-dark;
    }
  }

  .mat-tab-header-pagination-before,
  .mat-tab-header-rtl .mat-tab-header-pagination-after {
    padding-left: 0;
  }

  .mat-tab-header-rtl .mat-tab-header-pagination-before,
  .mat-tab-header-pagination-after {
    padding-right: 0;
  }
}
