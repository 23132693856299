@import "helpers/functions";

.simplebar-track.simplebar-track {
  &.simplebar-vertical {
    width: rem-calc(10);
  }
}

.simplebar-content {
  min-height: 100%;
}
