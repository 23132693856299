.dropdown-menu.mat-menu-panel {
  border-radius: 0;
  min-width: rem-calc(200);
  position: relative;
  top: rem-calc(10);
  overflow: visible;
  box-shadow: none;
  min-height: 0;

  &.mat-menu-above {
    top: - rem-calc(10);
  }

  &.dropdown-menu--dark {
    background-color: $color-brand-dark;
  }

  &.dropdown-menu--shift-left10 {
    left: rem-calc(10);
  }

  &.dropdown-menu--shift-right14 {
    left: rem-calc(-14);
  }

  &::after {
    content: "";
    position: absolute;
    top: -.5rem;
    left: rem-calc(20);
    transform: translateX(50%);
    width: 0;
    height: 0;
    border-left: .375rem solid transparent;
    border-right: .375rem solid transparent;
    border-bottom: .5rem solid $background-active-light;

    @at-root .dropdown-menu {
      &.mat-menu-before::after {
        left: auto;
        right: rem-calc(20);
      }

      &.dropdown-menu--right-arrow-shift30::after {
        right: rem-calc(30);
      }

      &.mat-menu-above::after {
        top: auto;
        bottom: -.5rem;
        border-bottom: none;
        border-top: .5rem solid $background-active-light;
      }

      &.dropdown-menu--dark::after {
        border-bottom-color: $color-brand-dark;
      }

      &.dropdown-menu--dark::after {
        border-top-color: $color-brand-dark;
      }
    }
  }

  .mat-menu-item {
    padding: .875rem 1.5rem;
    height: auto;
    line-height: 1.14;
    @include text-style($weight: $font-weight-semibold);
    transition: background-color .3s;

    span {
      vertical-align: middle;
    }

    @at-root .dropdown-menu.dropdown-menu--dark .mat-menu-item {
      color: $light-primary-text;

      &:hover,
      &:active {
        background-color: $color-brand;

        .dropdown-menu__error {
          color: lighten($danger, 20%);
        }
      }

      &:focus {
        [data-whatinput="keyboard"] & {
          box-shadow: inset 0 0 0 2px $color-brand-dark, inset 0 0 0 4px $white;
        }
      }
    }

    &[disabled] {
      opacity: .5;
    }

    .mat-spinner {
      display: inline-block;
      vertical-align: middle;
      margin-right: .75rem;

      circle {
        stroke: $background-active-light;
      }
    }
  }
}

.dropdown-menu {
  &__error {
    @include error-styles;
    display: block;
    margin: .25rem 0 0 1.75rem;
    white-space: normal;
    max-width: rem-calc(120);
  }
}
