.listing-page-inner-table {
  width: 100%;

  &__row:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  &__cell {
    padding: .5rem 1rem;
    vertical-align: middle;
    height: rem-calc(56);

    span {
      vertical-align: middle;
    }

    &--first {
      padding-left: 1.25rem;
      width: 100%;
    }

    &--with-icon {
      width: rem-calc(40);
      padding-right: .25rem;
      padding-left: .25rem;
    }
  }

  &-title {
    font-size: rem-calc(14);
    font-weight: $font-weight-semibold;
    padding: 1rem;
    background: $background-color;
  }

  &s-wrapper {
    border: 1px solid $border-color-secondary;
    border-top: none;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
