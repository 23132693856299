.mat-headline {
  @include heading-style($size: 'large');
  margin-bottom: 2rem;
}

.mat-title {
  @include heading-style($size: 'medium');
  margin-bottom: 1rem;
}

.mat-body-text {
  font-size: rem-calc(14);
  line-height: 1.4;
  margin-bottom: 1rem;

  &--margin-small {
    margin-bottom: rem-calc(12);
  }

  &--margin-large {
    margin-bottom: 1.5rem;
  }
}
