@use "sass:math";

@import "../settings/variables";
@import "functions";

@mixin reset-input-number-arrows() {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}

@mixin numerical-input($width: rem-calc(60), $mobileWidth: rem-calc(56)) {
  background-color: $background-color;
  border: 1px solid transparent;
  color: $dark-primary-text;
  -webkit-text-fill-color: $dark-primary-text;
  padding: .25rem .5rem;
  font-size: rem-calc(14);
  line-height: 1.3;
  text-align: right;
  border-radius: $border-radius;
  margin: 0 0 0 .5rem;
  width: $width;
  @include reset-input-number-arrows;

  @include until-tablet-landscape {
    width: $mobileWidth;
    font-size: rem-calc(12);
  }

  &[disabled] {
    background-color: $white;

  }

  &[readonly] {
    cursor: default;
    outline: none;
  }

  &:not([readonly]):focus {
    outline: none;
    background-color: $background-active-light;
    border: 1px solid $border-color-secondary;

    :host-context([data-whatintent="keyboard"]) & {
      border-color: $color-brand-dark;
      box-shadow: 0 0 0 1px $color-brand-dark;
    }

    &.ng-invalid {
      border-color: $danger;

      :host-context([data-whatintent="keyboard"]) & {
        border-color: $danger;
        outline: 1px solid $danger;
      }
    }
  }
}

@mixin base-page-layout-with-sidebar($topSpacing: 3rem, $bottomSpacing: 3rem, $horizontalSpacing: 2rem, $width: $sidebar-width--no-sidenav, $sidebarFixedWidth: false) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: $page-height;

  &__sidebar {
    display: flex;
    flex-direction: column;
    padding: $topSpacing $horizontalSpacing $bottomSpacing;
    max-width: 100%;
    background-color: $background-active-light;

    @if $sidebarFixedWidth {
      width: $width;
    } @else {
      width: 100%;

      @include for-desktop-up {
        width: $width;
      }
    }
  }
}

@mixin container-with-background($leftPosition: 0, $backgroundImageUrl: "~src/assets/images/windmils.jpg") {
  position: relative;

  @include for-tablet-landscape-up {
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: $leftPosition;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url('#{$backgroundImageUrl}');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      z-index: -1;
    }
  }
}

@mixin container-with-border($marginBottom: 1rem) {
  margin-bottom: $marginBottom;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: -1.5rem;
    right: -1.5rem;
    bottom: 0;
    border-bottom: 1px solid $border-color;
  }
}

@mixin text-ellipsis($width: 100%) {
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin error-styles($fontSize: rem-calc(10), $lineHeight: 1.2) {
  font-size: $fontSize;
  line-height: $lineHeight;
  color: $danger;
  margin: .25rem 0;
}

@mixin text-style($size: 'regular', $weight: null) {
  @if $size == 'small' {
    font-size: rem-calc(10);
    line-height: 1.2;
  } @else if ($size == 'medium') {
    font-size: rem-calc(12);
    line-height: 1.167;
  } @else if ($size == 'regular') {
    font-size: rem-calc(14);
    line-height: 1.143;
  }

  @if $weight {
    font-weight: $weight;
  }
}

@mixin heading-style($size: 'regular', $weight: $font-weight-semibold) {
  @if $size == 'large' {
    font-size: rem-calc(32);
    line-height: 1.16;
  } @else if ($size == 'medium') {
    font-size: rem-calc(18);
    line-height: 1.17;
  } @else if ($size == 'regular') {
    font-size: rem-calc(14);
    line-height: 1.143;
  } @else if ($size == 'small') {
    font-size: rem-calc(12);
    line-height: 1.167;
  }

  font-weight: $weight;
}

@mixin simple-button-styles(
  $paddingVertical: 0,
  $paddingHorizontal: 1.25rem,
  $width: auto,
  $marginTop: 0,
  $marginRight: 0,
  $marginBottom: 1.25rem,
  $marginLeft: auto,
  $fontWeight: $font-weight-regular) {
  display: block;
  width: $width;
  padding: $paddingVertical $paddingHorizontal;
  margin: $marginTop $marginRight $marginBottom $marginLeft;
  font-weight: $fontWeight;
}

@mixin fix-focus-base($color: $color-brand-dark) {
  [data-whatinput="mouse"] &:focus,
  [data-whatinput="touch"] &:focus {
    outline: none;
  }

  [data-whatinput="keyboard"] &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px $color;
  }
}

@mixin custom-focus($type: 'single', $color: $white, $colorSecondary: $color-brand-dark) {
  @if $type == 'single' {
    :host-context([data-whatinput="keyboard"]) &:focus {
      box-shadow: inset 0 0 0 2px $color;
    }
  } @else if $type == 'double' {
    :host-context([data-whatinput="keyboard"]) &:focus {
      box-shadow: inset 0 0 0 2px $color, inset 0 0 0 4px $colorSecondary;
    }
  }
}

@mixin flex-container($fullHeight: false, $direction: null, $justifyContent: center) {
  display: flex;
  align-items: center;
  justify-content: $justifyContent;

  @if $direction {
    flex-direction: $direction;
  }

  @if $fullHeight == true {
    height: 100%;
  }
}

@mixin link($color: $dark-primary-text, $fontSize: rem-calc(14)) {
  color: $color;
  font-size: $fontSize;
  text-decoration: underline;
  position: relative;
  transition: color .2s;
  display: inline-block;
  padding: .25em;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transform: scale3d(.8, .8, .8);
    transition: opacity .2s, transform .2s;
  }

  &:hover,
  &:active {
    color: darken($color, 20%);

    &::before {
      opacity: .2;
      background-color: $color;
      transform: scale3d(1, 1, 1);
    }
  }
}

@mixin dropdown-icon() {
  @include flex-container;
  transition: transform .3s;

  &::after {
    content: "";
    border-style: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: relative;
    top: -1px;
  }

  &--active {
    transform: rotate(180deg);
  }
}

@mixin for-minimal-screen-dimensions {
  @media (min-height: #{math.div($min-height, 16)}em) and (min-width: #{math.div($min-width, 16)}em) {
    @content;
  }
}

@mixin for-mobile-only {
  @media (max-width: #{math.div($tablet-portrait-breakpoint - 1, 16)}em) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: #{math.div($tablet-portrait-breakpoint, 16)}em) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: #{math.div($tablet-landscape-breakpoint, 16)}em) {
    @content;
  }
}

@mixin until-tablet-landscape {
  @media (max-width: #{math.div($tablet-landscape-breakpoint - 1, 16)}em) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: #{math.div($desktop-breakpoint, 16)}em) {
    @content;
  }
}

@mixin until-desktop {
  @media (max-width: #{math.div($desktop-breakpoint - 1, 16)}em) {
    @content;
  }
}

@mixin for-desktop-wide-up {
  @media (min-width: #{math.div($desktop-wide-breakpoint, 16)}em) {
    @content;
  }
}

@mixin until-desktop-wide {
  @media (max-width: #{math.div($desktop-wide-breakpoint - 1, 16)}em) {
    @content;
  }
}
