@use "sass:math";

$color-brand: #f26532;
$color-brand-dark: #2c2c2c;
$grey-light: #fafafa;
$grey: #f5f5f5;
$grey-dark: #e3e3e3;
$grey-darker: #cdcdcd;
$grey-darkest: #606060;
$white: #ffffff;
$almost-black: #161616;
$pink-light: #f4e8e4;

$danger: #ff5656;
$error: #c9000c;
$success: #52a73d;
$info: #fdff9b;

$light-primary-text: $white;
$dark-primary-text: $almost-black;
$background-color: $grey;

$text-color-inactive: #737373;

$border-color: $grey-dark;
$border-color-secondary: $grey-darker;

$background-active: $grey;
$background-active-light: $white;
$background-inactive: $grey-dark;
$background-inactive-dark: $grey-darker;

$controls-background-active: #ebebeb;
$controls-background-inactive: $grey-light;
$controls-background-selected: $grey-dark;
$controls-color-light: $grey-darkest;

$text-color-disabled: $grey-darker;

$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

$border-radius: 3px;

$sidenav-width: #{math.div(86, 16)}rem;
$sidenav-width--with-border: #{math.div(87, 16)}rem;

$sidebar-width--mobile: #{math.div(250, 16)}rem;
$sidebar-width--mobile-no-sidenav: #{math.div(250 + 87, 16)}rem;

$sidebar-width: #{math.div(300, 16)}rem;
$sidebar-width--no-sidenav: #{math.div(300 + 87, 16)}rem;
$sidebar-width--wide: #{math.div(1100, 16)}rem;

$top-bar-height: 2.5rem;
$panel-header-height: $top-bar-height;
$page-height: calc(100% - #{$panel-header-height});

$scrollbar-size: #{math.div(12, 16)}rem;

$tablet-portrait-breakpoint: 800;
$tablet-landscape-breakpoint: 1000;
$desktop-breakpoint: 1200;
$desktop-wide-breakpoint: 1400;

$min-height: 600;
$min-width: $tablet-portrait-breakpoint;

$color-palette-1: (
  100: lighten($color-brand-dark, 40%),
  500: $color-brand-dark,
  700: darken($color-brand-dark, 20%),
  contrast: (
    100: $dark-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  ),
);

$color-palette-2: (
  100: lighten($color-brand, 40%),
  500: $color-brand,
  700: darken($color-brand, 20%),
  contrast: (
    100: $dark-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  ),
);

$color-palette-red: (
  100: lighten($danger, 40%),
  500: $danger,
  700: darken($danger, 20%),
  contrast: (
    100: $dark-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  ),
);
