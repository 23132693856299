.controls-sidebar-accordion {
  &.mat-expansion-panel {
    margin: 0;
    border-bottom: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 0;

    .mat-expansion-panel-header.cdk-program-focused,
    .mat-expansion-panel-header.cdk-keyboard-focused {
      background-color: transparent;
    }

   .mat-accordion &:first-of-type,
   .mat-accordion &:last-of-type {
      border-radius: 0;
    }
  }

  .mat-accordion > &:last-of-type.mat-expanded,
  .mat-accordion > fieldset:last-of-type &.mat-expanded {
    border-bottom: none;
  }

  .mat-expansion-panel-header {
    height: 3.75rem;
    padding: 0 .875rem;
    @include fix-focus-base;

    @include for-tablet-landscape-up {
      padding: 0 1rem;
    }
  }

  .mat-expansion-panel-header-title {
    align-items: center;
  }

  .mat-expansion-panel-body {
    padding: 0 .875rem 1rem;

    @include for-tablet-landscape-up {
      padding: 0 1rem 1rem;
    }
  }

  &__title {
    display: flex;

    @include until-tablet-landscape {
      font-size: rem-calc(14);
    }
  }

  &__badge {
    margin: auto 0 auto 0.4em;
  }

  &__button.mat-button {
    margin-left: auto;
  }
}
