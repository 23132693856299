/* Customize website's scrollbar like Mac OS
Is not supported in Firefox and IE */

$scrollbar-thumb-color: rgba(0, 0, 22, 0.27); //#babac0

::-webkit-scrollbar {
  background-color: $white;
  width: .875rem;
}

::-webkit-scrollbar-track {
  background-color: $white;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-color;
  border-radius: 1rem;
  border: .25rem solid $white;
  min-height: 2.5rem;
}

::-webkit-scrollbar-button {
  display:none;
}

.simplebar-scrollbar {
  &::before {
    background-color:$scrollbar-thumb-color !important;
  }

  &.simplebar-visible {
    &::before {
      opacity: 1 !important;
    }
  }
}
