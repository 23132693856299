.base-table.mat-table {
  width: 100%;
  border-bottom: 1px solid $border-color;
  margin: 1rem 0;

  th {
    color: $dark-primary-text;
    background-color: $background-color;

    sup {
      position: relative;
      top: -.25rem;
      font-size: 75%;
    }
  }

  .mat-header-row,
  .mat-row {
    @include until-tablet-landscape {
      height: auto;
    }
  }

  .mat-row.highlighted td {
    background: rgba($success, .2);
  }

  th,
  td {
    @include text-style($size: 'medium');
    padding: rem-calc(10) .5rem;
    vertical-align: middle;
    text-align: center;
    border-bottom: none;
    white-space: nowrap;

    @include for-tablet-landscape-up {
      @include text-style;
      padding: rem-calc(10) 1rem;
    }

    &.align-left {
      text-align: left;
      padding-left: 1rem;
    }

    &:last-of-type {
      padding-right: 1rem;
    }
  }
}

.base-table-wrapper {
  &:first-of-type {
    .base-table {
      margin-top: 0;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.base-table-caption {
  margin: .25rem .5rem 1rem;
  @include text-style($size: 'medium');
  font-style: italic;

  @include for-tablet-landscape-up {
    @include text-style;
    margin: .25rem 1rem 1rem;
  }

  strong {
    font-weight: $font-weight-bold;
    font-style: normal;
  }
}
