.mat-flat-button {
  &.mat-flat-button {
    line-height: 2.5;
    font-size: .875rem;
    border-radius: $border-radius;

    &[disabled]  {
      background-color: $background-inactive;
      cursor: not-allowed;

      .mat-spinner {
        opacity: .5;
      }
    }
  }

  &:not([disabled]) {
    transition: background-color .3s;
    &.mat-accent:hover,
    &.mat-accent:active {
      background-color: darken($color-brand, 10%);
    }

    &.mat-primary:hover,
    &.mat-primary:active {
      background-color: lighten($color-brand-dark, 10%);
    }

    &.mat-warn:hover,
    &.mat-warn:active {
      background-color: darken($danger, 10%);
    }

    &.mat-accent,
    &.mat-primary,
    &.mat-warn {
      [data-whatinput="keyboard"] &:focus {
        box-shadow: inset 0 0 0 2px $color-brand-dark, inset 0 0 0 4px $white;
      }
    }
  }

  .mat-spinner {
    display: inline-block;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

.controls-sidebar-icon-button {
  background-color: $background-color;
  font-weight: $font-weight-regular;

  &--small.mat-button {
    @include text-style($size: 'small');
    padding: rem-calc(6) .5rem;

    .mat-spinner:not(:last-child),
    .icon:not(.icon--no-margin) {
      margin-right: .25rem;
    }
  }
}

.mat-flat-button,
.mat-button,
.mat-stroked-button,
.mat-icon-button {
  border-radius: $border-radius;

  .mat-button-wrapper > app-icon {
    vertical-align: unset;
  }
  [data-whatinput="keyboard"] &:focus {
    .mat-button-focus-overlay {
      display: none;
    }
  }
}

.mat-icon-button {
  app-icon {
    @include flex-container;
  }
}
