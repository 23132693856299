.controls-sidebar {
  &-section {
    padding: 1rem .875rem 0;
    border-bottom: 1px solid $border-color;
    flex: 0 0 auto;

    @include for-tablet-landscape-up {
      padding: 1.25rem 1rem 0;
    }

    &__title {
      margin-top: 1rem;
      font-weight: $font-weight-semibold;
      margin-bottom: .5rem;
    }
  }

  &-section1 {
    padding: .875rem;

    @include for-tablet-landscape-up {
      padding: 1rem;
    }

    &--with-border {
      border-bottom: 1px solid $border-color;
    }
  }

  &-sticky-container {
    padding: 0 1rem;
    flex: 0 0 auto;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: $background-active-light;
    border-top: 1px solid $border-color;
    z-index: 10;

    &:empty {
      display: none;
    }

    .mat-flat-button {
      margin: 1rem 0;
      display: block;
      padding: .5rem 1rem;
      width: 100%;
    }

    .mat-progress-bar {
      margin: 1rem 0;
      height: rem-calc(10);
      border-radius: rem-calc(10);

      .mat-progress-bar-buffer {
        background-color: $background-color;
      }
    }

    .info-box,
    .alert {
      margin-top: 1rem;
    }
  }
}

