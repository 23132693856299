.listing-page-pagination {
  &.mat-paginator {
    color: $controls-color-light;
    @include text-style;

    .mat-form-field {
     margin-top: 0;
    }

    .mat-paginator-page-size-select {
      width: rem-calc(64);
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border-top: none;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-select-value {
      //color: $controls-color-light;
      @include text-style;
    }

    .mat-select {
      border: 1px solid $border-color;
      padding: .5rem;
      border-radius: $border-radius;

      [data-whatinput="keyboard"] &:focus {
        box-shadow: inset 0 0 0 2px $color-brand-dark;
      }
    }

    .mat-paginator-icon {
      width: 1.5rem;
    }

    .mat-icon-button:not([disabled]) {
      &:hover,
      &:active {
        background-color: $background-color;
      }
    }
  }
}
