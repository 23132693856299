@import "helpers/mixins";

.base-form {
  position: relative;
  &__field {
    font-size: rem-calc(14);
    width: 100%;

    @include until-tablet-landscape {
      font-size: rem-calc(12);
    }

    &--with-hint .mat-form-field-subscript-wrapper {
      position: static;
    }

    &.mat-form-field {

      .mat-form-field-wrapper {
        margin: 0;
        padding-bottom: 1rem;
        line-height: 1.2;
      }

      &:not(.mat-form-field-has-label) {
        .mat-form-field-infix {
          border-top-width: 0;
        }

        .mat-form-field-outline {
          top: 0;
          background-color: $background-active-light;
          border-radius: $border-radius;
        }
      }

      .mat-form-field-infix {
        min-height: 2.5rem;
        padding: rem-calc(12) 0;
        display: flex;
      }

      .mat-form-field-outline-start {
        border-radius: $border-radius 0 0 $border-radius;
      }

      .mat-form-field-outline-end  {
        border-radius: 0 $border-radius $border-radius 0;
      }

      .mat-select-arrow-wrapper {
        transform: none;
      }

      .mat-form-field-flex {
        margin-top: 0;
        align-items: center;
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        display: flex;
        align-items: center;
        top: 0;
      }

      &.mat-form-field-disabled .mat-form-field-flex {
        color: $text-color-inactive;
      }

      &.mat-form-field-readonly {
        input,
        textarea,
        .mat-form-field-label {
          color: $text-color-inactive;
        }
        .mat-form-field-flex:hover .mat-form-field-outline-thick {
          opacity: 0;
        }
        .mat-form-field-flex:hover .mat-form-field-outline:not(.mat-form-field-outline-thick) {
          opacity: 1;
        }
      }

      &.mat-form-field-disabled {
        input,
        textarea {
          color: $text-color-inactive;
        }
        .mat-form-field-outline {
          background-color: $controls-background-inactive;
        }
      }
    }

    &.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
      font-size: rem-calc(10);
      padding: 0;
    }
  }

  &__checkbox.mat-checkbox {
    @include text-style('medium');

    &.is-invalid {
      .mat-checkbox-frame {
        border: rem-calc(2) solid $danger;
      }
    }

    .mat-checkbox-layout {
      margin: .5rem 0 1rem;
    }

    .mat-checkbox-inner-container {
      background-color: $background-active-light;
      width: rem-calc(12);
      height: rem-calc(12);
      border-radius: rem-calc(2);
    }

    .mat-checkbox-frame {
      border: 1px solid $border-color-secondary;
    }

    .mat-checkbox-checkmark {
      width: rem-calc(10);
      margin: auto;
    }

    &.cdk-keyboard-focused {
      .mat-checkbox-frame {
        border: 2px solid $color-brand-dark;
      }
      .mat-checkbox-persistent-ripple {
        opacity: 0;
      }
    }
  }

  &__radio.mat-radio-button {
    .mat-radio-outer-circle,
    .mat-radio-inner-circle,
    .mat-radio-container {
      width: rem-calc(12);
      height: rem-calc(12);
    }

    .mat-radio-outer-circle {
      border-width: 1px;
    }

    &.mat-radio-checked .mat-radio-inner-circle {
      transform: scale(.6);
    }

    &.cdk-program-focused:not(.mat-radio-disabled) .mat-radio-persistent-ripple {
      opacity: 0;
    }

    &.cdk-keyboard-focused:not(.mat-radio-disabled) {
      .mat-radio-outer-circle {
        border: 1px solid $color-brand-dark;
        box-shadow: 0 0 0 1px $color-brand-dark;
      }

      .mat-radio-persistent-ripple {
        opacity: 0;
      }
    }
  }

  &__checkbox.mat-checkbox .mat-checkbox-ripple,
  &__radio.mat-radio-button .mat-radio-ripple {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 2rem;
    width: 2rem;
  }

  &__label {
    display: block;
    margin-bottom: .25rem;
    @include text-style($size: 'medium', $weight: $font-weight-regular);

    app-numerical-control + & {
      margin-top: 1rem;
    }

    &-extras {
      font-style: italic;
      color: $controls-color-light;
      margin-left: .125rem;
    }
  }

  &__toggle.mat-slide-toggle  {
    .mat-slide-toggle-bar {
      border-radius: rem-calc(10);
      height: rem-calc(20);
    }

    .mat-slide-toggle-thumb {
      width: 1rem;
      height: 1rem;
      background-color: $background-active-light;
      box-shadow: none;
    }

    .mat-slide-toggle-thumb-container {
      width: 1rem;
      height: 1rem;
      top: rem-calc(2);
      left: rem-calc(2);
    }

    .mat-slide-toggle-ripple {
      display: none;
    }

    &.mat-checked  {
      .mat-slide-toggle-bar {
        background-color: $color-brand;
      }
      .mat-slide-toggle-thumb {
        background-color: $background-active-light;
      }
    }
  }

  &__group {
    margin-bottom: .5rem;
  }

  &__loader.mat-spinner {
    display: block;
    position: absolute;
    left: 1.5rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transform: translateX(-50%);
  }

  &__button.mat-flat-button {
    padding: .25rem 3rem;
    margin-right: .5rem;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &__hint {
    color: $controls-color-light;

    &--danger {
      color: $danger;
    }
  }

  &__error-message {
    @include error-styles;
    margin-bottom: 0.5rem;
  }
}


.base-form--enlarged {
  .base-form__group {
    margin-bottom: .75rem;
  }

  .base-form__field {
    .mat-form-field-subscript-wrapper {
      @include text-style($size: 'medium');
    }
  }

  .base-form__label {
    margin-bottom: .5rem;
    @include text-style;
  }

  .base-form__error {
    @include error-styles($fontSize: rem-calc(12));
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
